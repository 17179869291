<template>
  <div>
    <v-card class="mx-5 mt-5 mb-1 pos-card" outlined rounded-lg flat style="height: 264px">
      <!-- <v-carousel height="264" hide-delimiter-background hide-delimiters :show-arrows="false" cycle>
        <v-carousel-item v-for="(slide, i) in carouselList" :key="i" :src="slide" width="330">
          <VMapBox v-if="i === 0" style="height:264px;" :latitude= 13.7013266 :longitude= -89.226622 />
          <v-hover v-slot="{ hover }">
            <v-container :class="{'grey-50' : hover}" fill-height v-if="(!slide.length && i === 1) || hover">
              <v-row align="center" justify="center">
                <v-col cols="6" class="text-center">
                  <v-icon class="mb-2 d-block" color="grey-500" size="48">mdi-image</v-icon>
                  <span class="d-block body-1 grey-500--text">No se ha cargado la imagen</span>
                  <div class="ml-n2" v-show="hover" style="position:absolute; z-index: 1; margin-top: -70px">
                    <v-btn color="blue-500">Seleccionar imagen</v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
            <div v-else />
          </v-hover>
        </v-carousel-item>
      </v-carousel> -->
      <v-row class="" align="center" justify="center">
        <v-col class="text-center">
          <v-sheet class="transparent">
            <input ref="photo" type="file" accept="image/jpeg, image/png" style="display:none" @change="getLogoPreview">
            <v-hover v-slot:default="{hover}">
              <v-img :loading="logoPreview" :src="logoPreview ? logoPreview : photo" cover style="height: 265px; border-radius: 10px !important">
                <!-- <v-expand-transition> -->
                <v-container :class="{'grey-50 hover-stackholder' : hover}" fill-height v-if="!(!!photo || ''.length || !!logoPreview || ''.length)">
                  <v-col class="pa-0 text-center mx-auto">
                    <img :class="{'hover-stackholder-img' : hover}" contain :height="$route.name === 'PosRetrieveUpdate' ? 160 : 130" :src="require(`@/assets/backgrounds/background-${$route.name === 'PosRetrieveUpdate' ? 'pos' : 'branch'}-detail.svg`)" style="z-index:0; position: relative" />
                    <!-- <span class="d-block mt-2 subtitle-2 grey-500--text">No se ha cargado la imagen</span> -->
                    <v-btn v-if="hover" @click="$refs['photo'].click()" color="blue-500" absolute :style="`margin-top:55px; margin-left:-${$route.name === 'PosRetrieveUpdate' ?'115' : '136'}px; ${hover ? 'opacity: 9 !important; z-index: 1000' : ''}`">Seleccionar</v-btn>
                  </v-col>
                </v-container>
                <v-container v-if="hover" class="text-center" fill-height>
                  <div class="text-center mx-auto" v-if="logoPreview || ''.length">
                    <v-btn @click="resetLogoField" outlined width="80">Cancelar</v-btn>
                    <v-btn class="ml-2" @click="patchPhoto()" :loading="loading" color="blue-500" width="80">Guardar</v-btn>
                  </div>
                  <v-btn class="mx-auto" v-else @click="$refs['photo'].click()" color="blue-500">Actualizar</v-btn>
                </v-container>
                <!-- </v-expand-transition> -->
                <template v-if="photo" v-slot:placeholder>
                  <v-row class="fill-height ma-0" align="center" justify="center">
                    <v-progress-circular size="30" indeterminate color="blue lighten-4" />
                  </v-row>
                </template>
              </v-img>
            </v-hover>
          </v-sheet>
        </v-col>
      </v-row>
    </v-card>
    <v-toolbar class="mx-5 mt-5" color="transparent" flat height="36">
      <v-toolbar-title class="subtitle-2 grey-700--text font-weight-bold">Información básica <v-btn v-if="!hiddenEdit" @click="$emit('handleModal')" icon small><v-icon color="blue-500">mdi-pencil</v-icon></v-btn></v-toolbar-title>
      <v-spacer />
    </v-toolbar>
    <v-list class="px-5 mt-n1 transparent v-list-form py-0">
      <div v-for="(item, index) in itemList" :key="`${item.id}-${index}`">
      <v-list-item v-if="!item.title.includes('ID')" class="pa-0">
        <v-list-item-title class="grey-700--text subtitle-2">{{item.title}}</v-list-item-title>
        <v-list-item-subtitle v-if="item.title !== 'Tienda'" class="grey-300--text body-1">
          <template v-if="item.value">{{item.value}}</template>
          <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else-if="item.title === 'Tienda'">
          <router-link :to="{name: 'BranchRetrieveUpdate', params: {id: item.id } }" class="'blue-500--text text-decoration-none">
            {{item.value}}
          </router-link>
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item class="pa-0" v-else-if="item.isTooltip">
        <v-list-item-title class="grey-700--text subtitle-2">{{item.title}}</v-list-item-title>
        <v-list-item-subtitle class="grey-300--text body-1" v-if="item.value">
          <v-tooltip color="white-0" right transition="scale-transition">
            <template v-slot:activator="{on}">
              <v-btn class="grey-300--text body-1" @click="toClipboard(item.value)" text v-on="on" :ripple="false" style="justify-content: left;">{{item.value}}</v-btn>
            </template>
            <span class="d-block px-3 py-2">Copiar</span>
          </v-tooltip>
        </v-list-item-subtitle>
        <v-list-item-subtitle v-else>
          <span class="body-2 text--disabled font-italic">Sin especificar</span>
        </v-list-item-subtitle>
      </v-list-item>
      </div>
    </v-list>
  </div>
</template>

<script>
import VMapBox from "@/components/commons/VMapBox"
import GenericViewMixin from '@/mixins/GenericViewMixin'
export default {
  props: {
    carouselList: {
      type: Array,
      default: () => []
    },
    itemList: {
      type: Array,
      default: () => []
    },
    hiddenEdit: {
      type: Boolean,
      default: false
    },
    instance: {
      type: Object,
      default: () => ({})
    }
  },
  mixins: [
    GenericViewMixin
  ],
  components: {
    VMapBox
  },
  data: () => ({
    logoPreview: '',
    photo: '',
    loading: false,
    isUpdate: false
  }),
  computed: {
    paramsID () {
      return (item) => {
        return item.title === 'ID' ? item.value : ''
      }
    }
  },
  created () {
    this.photo = this.carouselList[1] ?? ''
  },
  methods: {
    getLogoPreview (e) {
      if (e.target?.files?.length) {
        this.logoPreview = URL.createObjectURL(e.target.files[0])
      } else {
        this.logoPreview = ''
      }
    },
    patchPhoto () {
      const maxSize = 1024
      this.photo = this.$refs.photo.files[0] ?? this.carouselList[1]
      let size = this.photo.size / maxSize / maxSize
      if (!this.photo.type.match('image.*')) {
        this.$dialog.message.error('Formato no permitido')
      } else if (size > 2) {
        this.$dialog.message.error('La imagen es demasiado grande, por favor agrega una menor a 2MB')
      } else {
        this.loading = true
        const payload = new FormData()
        if (this.photo) {
          payload.append('photo', this.photo, this.photo.name)
        }
        if (this.$route.name !== 'PosRetrieveUpdate') {
          payload.append('custom_id', this.instance.custom_id)
          payload.append('address', this.instance.address)
          payload.append('state', this.instance.state)
        }
        const source = this.$route.name === 'PosRetrieveUpdate' ? 'pos' : 'branches'
        this.$store.dispatch(`${source}/UPDATE`, {
          resource: source,
          payload,
          id: this.$route.params.id,
          headers: { 'Content-Type': 'multipart/form-data' }
        })
        .then((response) => {
          this.photo = response.data.photo
          this.$dialog.message.info('La imagen se ha actualizado')
          this.resetLogoField()
          this.loading = false
        })
        .catch((error) => {
          this.$dialog.message.error(this.parseErrors(error.response.data))
        })
      }
    },
    resetLogoField () {
      this.$refs.photo.type = 'text'
      this.$refs.photo.type = 'file'
      this.logoPreview = ''
      this.photo = this.carouselList[1]
    }
  }
}
</script>